:root {
  --off-white: #fafafa;
}
.nav {
  background-color: var(--off-white);
  position: sticky;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 1000;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.nav-logo {
  height: 60px;
  width: 60px;
}

.content {
  width: 61.8vw;

  display: block;
  margin-left: auto;
  margin-right: auto;
}


.nav-logo:hover {
  cursor: pointer;
}

.nav .nav-content {
  max-width: 900px;
  padding: 0rem 3rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-item {
  display: inline;
  margin-left: 2rem;
  color: #333;
}

.nav-item:hover {
  color: black;
  cursor: pointer;
}

.content-card {
  transition: 0.5s ease;
  opacity: .4;
}

.content-card:hover {
  opacity: 1;
}

.projects-container {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin: auto auto;
  grid-column-gap: 2%;
  grid-row-gap: 1.5%;
}

.space {
  min-height: 100vh;
  vertical-align: middle;
  text-align: center;
}

.section {
}


.section-dark {
  background: #333;
  color: white;
}

.section0 {
  /* background-size: cover;
  background-image: url('./birthday-background.jpeg'); */
}
.section0::before {
  content:"";
  background-size:cover;
  background-image: url('./birthday-background.jpeg');
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  opacity: 0.5;
  z-index: -1;
}

.section1 {
}

.background1 {
  content:"";
  background-size:inherit;
  background-image: url('./ny-skyline.jpeg');
  position: absolute;
  top: 100vh;
  left:0;
  opacity: 0.5;
  z-index: -1;
}

.section-content0 {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  min-height: 100vh;
  font-size: medium;
}

.section-content0 h1{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-content0 h3{
  margin: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .section-content0::before {
  content: "";
  background: url('./birthday-background.jpeg');
  background-size: cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
} */

.section-content1 {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  min-height: 100vh;
}
.section-content1::before {
  content:"";
  background: url('./ny-skyline.jpeg');
  background-size: cover;
  opacity: 0.5;
  /* top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  position:inherit;
  z-index: -1;
}
.section-content2 {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}
.section-content3 {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}
.section-content4 {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}
.section-content5 {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
}

h2 {
  min-height: 70vh;
}